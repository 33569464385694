<template>
  <section class="invoice-preview-wrapper">

    <b-row

      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >

          <b-row class="p-1">
            <b-col sm="12" md="7">
              Booking ID
              <h5> {{ invoiceData.detail.id }}</h5>
              Client Name
              <h5> {{invoiceData.user.zh_name }} {{invoiceData.user.en_name }} ({{invoiceData.user.member_code }})</h5>
              Redeem Date Time
              <h5>{{ invoiceData.detail.start }} To {{ invoiceData.detail.end }}</h5>
              Staff Name
              <h5>{{ invoiceData.detail.staff_name }}</h5>
              Package name
              <h5> {{ invoiceData.package.package_name }} </h5>
              Expire Date
              <h5> {{ invoiceData.package.expire_at}} </h5>
              Balance / Quantity ( Include current booking )
              <h5> {{ invoiceData.package.balance - 1}} / {{ invoiceData.package.quantity}} </h5>
            </b-col>
            <b-col  sm="12" md="5" style="border-left:1px solid #fff;">
              <VueSignaturePad
                v-if="invoiceData && invoiceData.detail.signature  === null"
                ref="signaturePad"
                :options="{ dotSize: 2, throttle: 16,backgroundColor: '#efefef',onBegin, onEnd }"
              />
              <img v-if="invoiceData && invoiceData.detail.signature  !== null" :src="invoiceData.detail.signature" style="width:100%">
            </b-col>
          </b-row>
          <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">

                 <b-row class="mt-2">
                  <b-col  sm="12" md="9">
                    <div class="font-weight-bold text-center mb-2">TERMS AND CONDITIONS</div>
                    <nl2br class="tnc" tag="div" :text="invoiceData.pt_terms" />

            </b-col>
            <b-col sm="12" md="3">
                <b-button
                class="mb-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                @click="save()"
                v-if="invoiceData && invoiceData.detail.signature  === null"
                variant="primary"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                class="mb-1"
                block
                @click="clear()"
                v-if="invoiceData && invoiceData.detail.signature  === null"
                variant="outline-warning"
              >
                Empty
    </b-button>
    <b-button block class="mb-1" variant="outline-secondary" :to="'/calendar'">Back</b-button>
            </b-col>
            </b-row>

             </b-card-body>
        </b-card>
      </b-col>
    </b-row>

      <div>
     <vue-html2pdf

        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="10000"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"

        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <b-row

              class="invoice-preview"
            >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            </div>
          </b-card-body>
          </b-card>


          <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">
                <div class="font-weight-bold text-center mb-2">TERMS AND CONDITIONS</div>
                <nl2br class="tnc" tag="div" :text="invoiceData.pt_terms" style="font-size:8px;"/>
             </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <img :src="signature" />
    </section>
    </vue-html2pdf>
   </div>



  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import Nl2br from 'vue-nl2br'
import VueSignaturePad from 'vue-signature-pad';
import VueHtml2pdf from 'vue-html2pdf'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    VueHtml2pdf,
    BRow,
    Nl2br,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
  },
  data() {
    return {
      invoiceData : {},
      items:[],
      signature:null,
      fields:['package_name','package_type','quantity','expire_at']
    }
  },
  created() {
    this.get_pt_data()
  },
  methods : {
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)
      this.signature = data
      this.upload_signature(data)
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    onBegin() {
      console.log('=== Begin ===');
    },
    onEnd() {
      console.log('=== End ===');
    },
    get_pt_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/booking/'+router.currentRoute.params.id+'/')
        .then(res => {
          this.invoiceData = res.data
          console.log(this.invoiceData)
        })
    },
    upload_signature(signature) {
      this.$http.post(process.env.VUE_APP_API_BASE+'/package/'+router.currentRoute.params.id+'/signature/', {"signature" : signature} )
        .then(res => {
          this.$router.push('/calendar').catch(() => {})
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  .tnc {
    line-height:2rem;
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
